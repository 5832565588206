export enum CouponType {
  VALID = "valid",
  FUTURE = "future",
  EXPIRED = "expired"
}

export type CouponListParams = {
  status: CouponType;
};

export type CouponCard = {
  id: number;
  code: string;
  end_at: string;
  event: string;
  name: string;
  note: string;
  series: string;
  start_at: string;
};

export type CouponListResponse = CouponCard[];
