import requestEco from "@/apis/httpEco";
import { CouponListParams, CouponListResponse } from "@/typings/coupon";
import { AxiosPromise } from "axios";

export function getCouponsList(
  params: CouponListParams
): AxiosPromise<CouponListResponse> {
  return requestEco({
    url: `/coupons/`,
    method: "GET",
    params
  });
}
